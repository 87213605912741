<template>
  <label class='switch'>
    <div class='slr'
      :class='{ "is-active": modelValue }'>
    </div>
    <input class='hidden'
      type='checkbox'
      :checked='modelValue'
      @click.stop
      @change='$emit("update:modelValue", $event.target.checked)'>
  </label>
</template>

<script>
  export default {
    props: {
      modelValue: Boolean
    },

    emits: ['update:modelValue']
  }
</script>

<style lang='scss' scoped>
  .switch {
    display: inline-block;
  }

  .slr {
    position: relative;
    width: 36px;
    height: 20px;
    background-color: theme('colors.gray.300');
    border-radius: 12px;

    &::before {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      background-color: white;
      border-radius: 6px;
      transition: left 0.2s ease-in-out;
      content: '';
    }

    &.is-active {
      background-color: theme('colors.primary.500');

      &::before {
        left: 20px;
      }
    }
  }
</style>
