<template>
  <label class='radio'>
    <div class='icon ico'
      :class='{ "is-checked": checked }'>
      {{ checked ? 'radio_button_checked' : 'radio_button_unchecked' }}
    </div>
    <slot/>
    <input class='hidden'
      type='radio'
      :checked='checked'
      @click.stop
      @change='$emit("update:modelValue", value)'>
  </label>
</template>

<script>
  import { computed } from 'vue'

  export default {
    props: {
      value: String,
      modelValue: String
    },

    emits: ['update:modelValue'],

    setup (props) {
      let checked = computed(() => props.value === props.modelValue)
      return { checked }
    }
  }
</script>

<style lang='scss' scoped>
  .radio {
    display: inline-flex;
    align-items: center;
  }

  .ico {
    color: theme('colors.gray.700');

    &.is-checked {
      color: theme('colors.primary.500');
    }
  }
</style>
