import axios from '@/axios'

const actions = {
  async uploadImage (context, image) {
    let formData = new FormData()
    formData.append('file', image)

    let response = await axios.post('/api/uploadImage', formData)
    return response.data
  }
}

export default {
  namespaced: true,
  actions
}
