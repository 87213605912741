<template>
  <button class='button'
    :class='{
      "is-outlined": outlined,
      "is-loading": loading
    }'
    :disabled='disabled'>
    <transition name='prg'>
      <div class='icon prg'
        v-show='loading'>
        sync
      </div>
    </transition>
    <slot/>
  </button>
</template>

<script>
  export default {
    props: {
      outlined: Boolean,
      loading: Boolean,
      disabled: Boolean
    }
  }
</script>

<style lang='scss' scoped>
  .button {
    display: inline-flex;
    align-items: center;
    padding: 0 24px;
    border: 2px solid transparent;
    height: 40px;
    color: white;
    background-color: theme('colors.primary.500');
    border-radius: 4px;
    font-weight: theme('fontWeight.medium');
    white-space: nowrap;

    &:hover {
      background-color: theme('colors.primary.300');
    }

    &:disabled {
      color: theme('colors.gray.100');
      background-color: theme('colors.gray.300');
    }

    &.is-outlined {
      border-color: theme('colors.primary.500');
      color: theme('colors.primary.500');
      background-color: transparent;

      &:hover {
        color: theme('colors.primary.300');
      }

      &:disabled {
        border-color: theme('colors.gray.300');
        color: theme('colors.gray.300');
      }
    }

    &.is-loading {
      pointer-events: none;
    }
  }

  .prg {
    margin: 0 8px 0 -8px;
    animation: spin 1s linear infinite;

    &-enter-from,
    &-leave-to {
      font-size: 0;
    }

    &-enter-active,
    &-leave-active {
      transition: font-size 0.2s ease-in-out;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(-360deg);
    }
  }
</style>
