<template>
  <div class='datetime'>
    <template v-if='label'>
      <div class='mb-8 text-sm text-gray-500'>
        <div class='truncate'>{{ label }}</div>
      </div>
    </template>
    <input class='inp'
      type='datetime-local'
      placeholder='YYYY-MM-DDThh:mm'
      :min='convertToLocalString(min)'
      :max='convertToLocalString(max)'
      :disabled='disabled'
      :value='convertToLocalString(modelValue)'
      @input='$emit("update:modelValue", convertToString($event.target.value))'>
  </div>
</template>

<script>
  export default {
    props: {
      label: String,
      min: String,
      max: String,
      disabled: Boolean,
      modelValue: String
    },

    emits: ['update:modelValue'],

    setup () {
      let convertToLocalString = function (value) {
        return new Date(value)
          .toLocaleString('sv-SE', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          })
          .replace(' ', 'T')
      }

      let convertToString = function (value) {
        return new Date(value).toISOString()
      }

      return {
        convertToLocalString,
        convertToString
      }
    }
  }
</script>

<style lang='scss' scoped>
  .inp {
    padding: 0 12px;
    border: 1px solid theme('colors.gray.300');
    width: 100%;
    height: 40px;
    border-radius: 4px;

    &:focus,
    &:hover {
      border-color: theme('colors.primary.500');
    }

    &:disabled {
      border-color: theme('colors.gray.300');
      color: theme('colors.gray.500');
    }

    &::placeholder {
      color: theme('colors.gray.500');
    }
  }
</style>
