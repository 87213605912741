import firebase from 'firebase/app'
import 'firebase/auth'

firebase.initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY
})

export const getCurrentUser = function () {
  let user = firebase.auth().currentUser
  if (user) return user

  return new Promise(resolve => {
    let unsubscribe = firebase.auth()
      .onAuthStateChanged(user => {
        resolve(user)
        unsubscribe()
      })
  })
}

export default firebase
