<template>
  <div class='textarea'>
    <template v-if='label'>
      <div class='mb-8 text-sm text-gray-500'>
        <div class='truncate'>{{ label }}</div>
      </div>
    </template>
    <textarea class='tex'
      :placeholder='placeholder'
      :disabled='disabled'
      :value='modelValue'
      @input='$emit("update:modelValue", $event.target.value)'>
    </textarea>
  </div>
</template>

<script>
  export default {
    props: {
      label: String,
      placeholder: String,
      disabled: Boolean,
      modelValue: String
    },

    emits: ['update:modelValue']
  }
</script>

<style lang='scss' scoped>
  .tex {
    padding: 12px;
    border: 1px solid theme('colors.gray.300');
    width: 100%;
    min-height: 120px;
    border-radius: 4px;
    resize: vertical;

    &:focus,
    &:hover {
      border-color: theme('colors.primary.500');
    }

    &:disabled {
      border-color: theme('colors.gray.300');
      color: theme('colors.gray.500');
    }

    &::placeholder {
      color: theme('colors.gray.500');
    }
  }
</style>
