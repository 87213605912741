import axios from '@/axios'

const state = {
  broadcasts: null
}

const mutations = {
  setBroadcasts (state, broadcasts) {
    state.broadcasts = broadcasts
  }
}

const actions = {
  async fetchBroadcasts (context) {
    let response = await axios.post('/api/listBroadcasts')
    context.commit('setBroadcasts', response.data)
  },

  async getBroadcast (context, { id }) {
    let response = await axios.post('/api/getBroadcast', { id })
    return response.data
  },

  async createBroadcast (context, broadcast) {
    let response = await axios.post('/api/createBroadcast', broadcast)
    return response.data
  },

  async updateBroadcast (context, broadcast) {
    let response = await axios.post('/api/updateBroadcast', broadcast)
    return response.data
  },

  async deleteBroadcast (context, { id }) {
    await axios.post('/api/deleteBroadcast', { id })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
