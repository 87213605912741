<template>
  <component :is='component'/>
</template>

<script>
  import {
    computed, ref, shallowRef, watch, provide,
    defineAsyncComponent
  } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'

  export default {
    setup () {
      let route = useRoute()
      let store = useStore()

      let layout = computed(() => {
        if (route.matched.length === 0) return
        return route.meta.layout ?? 'default'
      })

      let component = shallowRef(null)

      watch(layout, layout => {
        if (!layout) return
        component.value = defineAsyncComponent(() =>
          import(`@/layouts/${layout}/${layout}.vue`)
        )
      }, { immediate: true })

      store.dispatch('auth/watchUser')

      let mode = ref('newbie')

      provide('mode', mode)

      let count = 0
      let keys = [
        'KeyI', 'KeyA', 'KeyM', 'KeyN', 'KeyO', 'KeyT',
        'KeyN', 'KeyE', 'KeyW', 'KeyB', 'KeyI', 'KeyE'
      ]

      document.addEventListener('keyup', event => {
        if (mode.value !== 'newbie') return

        count = keys[count] === event.code ? count + 1 : 0

        if (count === keys.length) {
          mode.value = 'pupil'
        }
      })

      return { component }
    }
  }
</script>

<style lang='scss'>
  @use 'tailwindcss/utilities';
  @use '@/styles/reboot';
  @use '@/styles/icons';
</style>
