import { createStore } from 'vuex'
import auth from './modules/auth'
import broadcasts from './modules/broadcasts'
import images from './modules/images'
import integrations from './modules/integrations'
import messages from './modules/messages'

export default createStore({
  modules: {
    auth,
    broadcasts,
    images,
    integrations,
    messages
  }
})
