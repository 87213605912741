import axios from '@/axios'

const state = {
  integrations: null
}

const mutations = {
  setIntegrations (state, integrations) {
    state.integrations = integrations
  }
}

const actions = {
  async fetchIntegrations (context) {
    let response = await axios.post('/api/listIntegrations')
    context.commit('setIntegrations', response.data)
  },

  async createIntegration (context, integration) {
    let response = await axios.post('/api/createIntegration', integration)
    return response.data
  },

  async updateIntegration (context, integration) {
    let response = await axios.post('/api/updateIntegration', integration)
    return response.data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
