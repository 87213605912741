import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/pages/home/home.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'blank'
    },
    component: () => import('@/pages/login/login.vue')
  },
  {
    path: '/rich-messages/:id',
    name: 'rich-message',
    props: true,
    component: () => import('@/pages/rich-message/rich-message.vue')
  },
  {
    path: '/broadcasts/:id',
    name: 'broadcast',
    props: true,
    component: () => import('@/pages/broadcast/broadcast.vue')
  }
]

export default createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition ?? { top: 0 }
  }
})
