<template>
  <div class='select'>
    <template v-if='label'>
      <div class='mb-8 text-sm text-gray-500'>
        <div class='truncate'>{{ label }}</div>
      </div>
    </template>
    <select class='sel'
      :class='{ "is-empty": !modelValue }'
      :disabled='disabled'
      :value='modelValue'
      @change='$emit("update:modelValue", $event.target.value)'>
      <template v-if='placeholder'>
        <option value=''>{{ placeholder }}</option>
      </template>
      <slot/>
    </select>
    <div class='icon car'>arrow_drop_down</div>
  </div>
</template>

<script>
  export default {
    props: {
      label: String,
      placeholder: String,
      disabled: Boolean,
      modelValue: String
    },

    emits: ['update:modelValue']
  }
</script>

<style lang='scss' scoped>
  .select {
    position: relative;
  }

  .sel {
    padding: 0 40px 0 12px;
    border: 1px solid theme('colors.gray.300');
    width: 100%;
    height: 40px;
    border-radius: 4px;
    appearance: none;

    &:focus,
    &:hover {
      border-color: theme('colors.primary.500');
    }

    &:disabled {
      border-color: theme('colors.gray.300');
      color: theme('colors.gray.500');
    }

    &.is-empty {
      color: theme('colors.gray.300');
    }
  }

  .car {
    position: absolute;
    right: 8px;
    bottom: 8px;
    color: theme('colors.gray.300');
    pointer-events: none;
  }
</style>
