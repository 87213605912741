<template>
  <div class='dialog'>
    <teleport
      to='#app'
      :disabled='noTeleport'>
      <div class='dialog-teleport'>
        <transition name='cnt'>
          <div class='cnt'
            v-show='visible'>
            <slot/>
          </div>
        </transition>
        <transition name='ovr'>
          <div class='ovr'
            v-show='visible'
            @click='!persistent && hide()'>
          </div>
        </transition>
      </div>
    </teleport>
  </div>
</template>

<script>
  import { ref, onBeforeUnmount } from 'vue'

  export default {
    props: {
      persistent: Boolean,
      noTeleport: Boolean
    },

    setup () {
      let visible = ref(false)

      let show = function () {
        if (visible.value) return
        visible.value = true
        document.body.style.overflow = 'hidden'
      }

      let hide = function () {
        if (!visible.value) return
        visible.value = false
        document.body.style.overflow = ''
      }

      onBeforeUnmount(() => {
        hide()
      })

      return { visible, show, hide }
    }
  }
</script>

<style lang='scss' scoped>
  .cnt {
    position: fixed;
    top: 15%;
    right: 24px;
    left: 24px;
    z-index: theme('zIndex.dialog');
    overflow-y: auto;
    margin: auto;
    max-width: 600px;
    max-height: 70%;
    background-color: white;
    border-radius: 4px;

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: scale(1.025);
    }

    &-enter-active,
    &-leave-active {
      transition-property: opacity, transform;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
    }
  }

  .ovr {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: calc(theme('zIndex.dialog') - 1);
    background-color: rgba(0, 0, 0, 0.9);

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }

    &-enter-active,
    &-leave-active {
      transition: opacity 0.2s ease-in-out;
    }
  }
</style>
