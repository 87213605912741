import Button from './button/button.vue'
import Datetime from './datetime/datetime.vue'
import Dialog from './dialog/dialog.vue'
import Input from './input/input.vue'
import Menu from './menu/menu.vue'
import Radio from './radio/radio.vue'
import Select from './select/select.vue'
import Switch from './switch/switch.vue'
import Textarea from './textarea/textarea.vue'

export default {
  install (app) {
    app.component('button-element', Button)
    app.component('datetime-element', Datetime)
    app.component('dialog-element', Dialog)
    app.component('input-element', Input)
    app.component('menu-element', Menu)
    app.component('radio-element', Radio)
    app.component('select-element', Select)
    app.component('switch-element', Switch)
    app.component('textarea-element', Textarea)
  }
}
