<template>
  <div class='menu'
    ref='menu'>
    <slot
      name='activator'
      :on='{ click: open }'/>
    <transition name='con'>
      <div class='con'
        v-show='opened'
        :class='{
          "is-right": right,
          "is-up": up
        }'>
        <slot
          name='content'
          :close='close'/>
      </div>
    </transition>
  </div>
</template>

<script>
  import { ref, onBeforeUnmount } from 'vue'

  export default {
    props: {
      right: Boolean,
      up: Boolean
    },

    setup () {
      let menu = ref(null)
      let opened = ref(false)

      let listener = function (event) {
        if (!menu.value.contains(event.target)) {
          opened.value = false
          document.removeEventListener('click', listener)
        }
      }

      let open = function () {
        if (opened.value) return
        opened.value = true
        document.addEventListener('click', listener)
      }

      let close = function () {
        if (!opened.value) return
        opened.value = false
        document.removeEventListener('click', listener)
      }

      onBeforeUnmount(() => {
        close()
      })

      return { menu, opened, open, close }
    }
  }
</script>

<style lang='scss' scoped>
  .menu {
    position: relative;
    display: inline-block;
  }

  .con {
    position: absolute;
    z-index: theme('zIndex.menu');
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);

    &::after {
      position: absolute;
      width: 100%;
      height: 24px;
      content: '';
    }

    &.is-right {
      right: 0;
    }

    &.is-up {
      bottom: 100%;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }

    &-enter-active,
    &-leave-active {
      transition: opacity 0.2s ease-in-out;
    }
  }
</style>
