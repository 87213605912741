import axios from '@/axios'

const state = {
  messages: null
}

const mutations = {
  setMessages (state, messages) {
    state.messages = messages
  }
}

const actions = {
  async fetchMessages (context) {
    let response = await axios.post('/api/listMessages')
    context.commit('setMessages', response.data)
  },

  async getMessage (context, { id }) {
    let response = await axios.post('/api/getMessage', { id })
    return response.data
  },

  async createMessage (context, { name }) {
    let response = await axios.post('/api/createMessage', { name })
    return response.data
  },

  async updateMessage (context, message) {
    let response = await axios.post('/api/updateMessage', message)
    return response.data
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
