import firebase from '@/firebase'

const state = {
  user: false
}

const mutations = {
  setUser (state, user) {
    state.user = user
  }
}

const actions = {
  watchUser (context) {
    firebase.auth().onAuthStateChanged(user => {
      user = user ? Object.freeze({ ...user }) : null
      context.commit('setUser', user)
    })
  },

  async signIn (context, { email, password }) {
    await firebase.auth()
      .signInWithEmailAndPassword(email, password)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
